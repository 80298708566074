.light--blue--color-shade--6 {
  background: #0065b7;
}

.brand--bg--color {
  background: #003399;
}

.brand--text--color {
  color: #003399 !important;
}

.light--blue--color {
  background: #dde2ec;
}

.in--active--blue--color {
  background: #7d98c9;
}

.black--color-text {
  color: #141b2f;
}

.white--color-text {
  color: #fafafa !important;
}

.blue--color--text {
  color: #003399;
}

.grey--color--text {
  color: #5c5c5c;
}

.green--color--text {
  color: #269f00;
}

.red--color--text {
  color: #c43a4b;
}

.black--color--00--text {
  color: #000000;
}

.digit--blue--color {
  color: #05c098;
}

.digit--red--color {
  color: #f5555f;
}

.bg--digit--blue--color {
  background: #05c098;
}

.bg--digit--red--color {
  background: #f5555f;
}

.white--background--color {
  background-color: #fafafa;
}

.black--background--color {
  background-color: #141b2f;
}

.black--color--00--bg {
  background: #000000 !important;
}

.blue--background--color {
  background-color: #003399;
}

.light--grey--color-shade--3 {
  background-color: #f0f0f0;
}

.light--blue--bg-color-shade--1 {
  background-color: #7d96c9;
}

.light--blue--bg-color-shade--2 {
  background-color: #c8d2e7;
}

.light--bg--color {
  background-image: linear-gradient(#86adfd, #486aae) !important;
}

.dark--blue--bg-color-shade--1 {
  background-color: #131b2e;
}

.bg-green-dark {
  background: #269f00;
}

.bg-red-dark {
  background: #C43A4B;
}

.brand--color--border {
  border: 1px solid #003399;
}

.brand--border--blue {
  border: 1px solid #003399;
}

.red--color--border {
  border: 1px solid #c43a4b;
}

.green--color--border {
  border: 1px solid #269f00;
}

.black--color--border {
  border: 1px solid #141b2f;
}

.white--color--border {
  border: 1px solid #fafafa;
}

.n-primary-black-bg {
  background: #001316;
}

.n-primary-cyan-bg {
  background: #75ffff;
}

.n-primary-orange-bg {
  background: #d16014;
}

.n-primary-white-bg {
  background: #fffaf7;
}

.n-primary-dark-orge-bg {
  background: #9e6240;
}

.n-primary-purple-bg {
  background: #776871;
}

.n-primary-green-bg {
  background: #339581;
}

.active--cyan--color {
  background: #75ffff;
  border: 1px solid #75ffff;
}

.in--active--cyan--color {
  background: #2d898b;
  border: 1px solid #2d898b;
}

.n-primary-black-txt {
  color: #001316;
}

.n-primary-black-txt-imp {
  color: #001316 !important;
}

.n-primary-cyan-txt {
  color: #75ffff;
}

.n-primary-orange-txt {
  color: #d16014;
}

.n-primary-white-txt {
  color: #fffaf7;
}

.n-grey-color-100-txt {
  color: #7f8686;
}

.n-primary-dark-orge-txt {
  color: #9e6240;
}

.n-primary-purple-txt {
  color: #776871;
}

.n-primary-green-txt {
  color: #339581;
}

.n-primary-cyan-border {
  border: 1px solid #75ffff;
}

.n-primary-white-border {
  border: 1px solid #fffaf7;
}

.neuePowerTrial-Regular {
  font-family: "neuePowerTrial-Regular";
}

.neuePowerTrial-Medium {
  font-family: "neuePowerTrial-Medium";
}

.canela-LightItalic {
  font-family: "canela-LightItalic";
}

.neueMontreal-Thin {
  font-family: "neueMontreal-Thin";
}

.neueMontreal-Book {
  font-family: "neueMontreal-Book";
}

.neueMontreal-Regular {
  font-family: "neueMontreal-Regular";
}

.neue-regular {
  font-family: "Neue-regular";
}

.Neue-bold {
  font-family: "Neue-bold";
}

@font-face {
  font-family: "PPNeueMontreal-Light";
  src: url("../fonts/Neue-Montreal/PPNeueMontreal-Light.ttf");
}
@font-face {
  font-family: "Neue-light";
  src: url("../fonts/Neue-Power/NeuePowerTrial-Light.ttf");
}
@font-face {
  font-family: "Neue-regular";
  src: url("../fonts/Neue-Power/NeuePowerTrial-Regular.ttf");
}
@font-face {
  font-family: "Neue-bold";
  src: url("../fonts/Neue-Power/NeuePowerTrial-Bold.ttf");
}
@font-face {
  font-family: "Neue-montreal-medium";
  src: url("../fonts/Neue-Montreal/PPNeueMontreal-Medium.otf");
}
@font-face {
  font-family: "Neue-montreal-regular";
  src: url("../fonts/Neue-Montreal/PPNeueMontreal-Regular.otf");
}
@font-face {
  font-family: "Canela-RegularItalic";
  src: url("../fonts/canela-text-trial-cufonfonts/Canela-RegularItalic-Trial.otf");
}
@font-face {
  font-family: "Poppins-Regular";
  src: url("../fonts/Poppins-Regular.ttf");
}
@font-face {
  font-family: "CanelaDeck-LightItalic";
  src: url("../fonts/canela-text-trial-cufonfonts/Canela-LightItalic-Trial.otf");
}
@font-face {
  font-family: "ppneuemontreal-thin";
  src: url("../fonts/Neue-Montreal/PPNeueMontreal-Thin.otf");
}
@font-face {
  font-family: "PPNeueMontreal-Regular";
  src: url("../fonts/Neue-Montreal/PPNeueMontreal-Regular.otf");
}
@font-face {
  font-family: "PPNeueMontreal-Medium";
  src: url("../fonts/Neue-Montreal/PPNeueMontreal-Medium.ttf");
}
.roboto-sans-serif {
  font-family: "Roboto", sans-serif;
}

.pp-neue-montreal {
  font-family: "PP Neue Montreal", sans-serif;
}

.neue-power-sans-serif {
  font-family: "Neue Power", sans-serif;
}

.neue-bold {
  font-family: "Neue-bold";
}

.poppins-sans-serif {
  font-family: "Poppins", sans-serif;
}

.new_buttons_theme {
  padding: 0.688rem 1.125rem;
  border-radius: 6px;
  font-size: 0.875rem;
  border: 0px;
  cursor: pointer;
  height: 100%;
}

.new_buttons_theme_a {
  padding: 0.688rem 1.125rem;
  border-radius: 6px;
  font-size: 0.875rem;
  border: 1px solid #75ffff;
  cursor: pointer;
}

.font-12 {
  font-size: 0.75rem !important;
  line-height: 0.875rem !important;
}

.font-14 {
  font-size: 0.875rem;
  line-height: 1rem;
}

.font-wt-300 {
  font-weight: 300;
}

.font-wt-400 {
  font-weight: 400;
}

.font-wt-500 {
  font-weight: 500;
}

.font-wt-600 {
  font-weight: 600;
}

.font-wt-700 {
  font-weight: 700;
}

.banner-main-heading {
  font-family: "neuePowerTrial-Regular";
  font-size: 6.25rem;
  font-weight: 400;
  line-height: 7.5rem;
  text-align: center;
  color: #fff;
}
.banner-main-heading span {
  font-family: "canela-LightItalic";
  font-weight: 300;
}

.common-heading {
  font-family: "neuePowerTrial-Regular";
  font-size: 3.125rem;
  font-weight: 500;
  line-height: 3.75rem;
}
.common-heading span {
  font-family: "canela-LightItalic";
  font-style: italic;
  font-weight: 300;
}

.big-heading {
  font-family: "neuePowerTrial-Medium";
  font-size: 4rem;
  font-weight: 400;
  line-height: 4.75rem;
  text-align: left;
}

.font-70 {
  font-family: "neuePowerTrial-Regular";
  font-size: 4.375rem;
  font-weight: 500;
  line-height: 5.25rem;
  text-align: center;
}

.buttons__theme {
  padding: 0.688rem 1.125rem;
  border-radius: 6px;
  font-size: 0.9625vw;
  font-weight: 500;
}

.heading--text {
  font-size: 3.125rem;
  font-weight: 600;
  line-height: 4.688rem;
}

.sub-heading--text {
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 2.25rem;
}
.sub-heading--text span {
  color: #003399;
}

.sub-heading--text-16 {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-family: "neueMontreal-Regular";
  font-size: 16px;
  font-weight: 400;
}

body {
  background-color: #fafafa;
}

a {
  text-decoration: none;
}

ul,
li {
  list-style: none;
}

img {
  width: 100%;
}

.mt-1 {
  margin-top: 1rem !important;
}

.border-radius-20 {
  border-radius: 20px;
}

.buttons__theme {
  padding: 0.688rem 1.125rem;
  border-radius: 6px;
  color: #fafafa;
  font-size: 0.9625vw;
  font-weight: 500;
  cursor: pointer;
}

.sub--btn {
  background-color: #75ffff;
  padding: 0.875rem;
  min-width: 25rem !important;
  display: block;
  color: #fafafa;
  border-radius: 6px;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.375rem;
  text-align: center;
  margin: 4rem auto;
  margin-top: 1rem;
}

.base-btn a {
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.75rem;
  text-align: center;
  padding: 1rem 4rem;
  background: #75ffff;
  border-radius: 64px;
  color: #001316;
}

.invest--large--btn {
  padding: 1.25rem 12rem;
  border-radius: 78px;
  font-size: 1.625rem;
  font-weight: 500;
  line-height: 2.375rem;
  text-align: center;
  background-color: #003399;
  color: #fafafa;
}

.crt-btn {
  background-color: #75ffff;
  padding: 0.875rem;
  max-width: 25rem !important;
  display: block;
  color: #001316;
  border-radius: 6px;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.375rem;
  text-align: center;
  margin: 4rem auto;
}

.know--more--btn {
  padding: 1rem 4.875rem;
  background-color: #003399;
  color: #fafafa;
  font-size: 1rem;
  font-family: "Inter", sans-serif;
  border-radius: 100px;
}

.text--center {
  text-align: center;
}

.text--center-imp {
  text-align: center !important;
}

.text--right {
  text-align: right;
}

.section-heading {
  font-family: "neuePowerTrial-Medium";
  font-size: 3.125rem;
  font-weight: 500;
  line-height: 3.75rem;
}

.heading--text {
  font-size: 3.125rem;
  font-weight: 600;
  line-height: 4.688rem;
}

.sub-heading--text {
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 2.25rem;
}
.sub-heading--text span {
  color: #003399;
}

.heading--text--30 {
  font-size: 1.875rem;
  font-weight: 600;
  line-height: 2.813rem;
}

.heading--text--24 {
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2.25rem;
}

.heading--text--22 {
  font-size: 1.375rem;
  font-weight: 500;
  line-height: 2.125rem;
}

.heading--text--20 {
  font-size: 1.25rem !important;
  font-weight: 400 !important;
  line-height: 1.875rem !important;
}

.heading--text--18 {
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.75;
}

.sub-heading--text-16 {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
}

.small--text-12 {
  font-size: 0.75rem !important;
  font-weight: 400;
  line-height: 1.125rem;
  text-align: left;
}

.m-auto {
  margin: auto;
}

.m-0 {
  margin: 0rem !important;
}

.mb-0 {
  margin-bottom: 0rem !important;
}

.mb-1 {
  margin-bottom: 1rem;
}

.mb-1rem {
  margin-bottom: 1rem !important;
}

.mb-2 {
  margin-bottom: 2rem;
}

.mb-5 {
  margin-bottom: 5.25rem;
}

.mb-30 {
  margin-bottom: 1.875rem;
}

.ml-1 {
  margin-left: 1rem;
}

.ml-2 {
  margin-left: 2rem;
}

.mr-05 {
  margin-right: 0.5rem;
}

.ml-40 {
  margin-left: 2.5rem;
}

.mr-1 {
  margin-right: 0.5rem;
}

.mr-2 {
  margin-right: 2rem;
}

.mt-05 {
  margin-top: 0.5rem;
}

.mt-2 {
  margin-top: 2.5rem;
}

.mtb-4-5 {
  margin-top: 4.5rem !important;
  margin-bottom: 4.5rem !important;
}

.width--12 {
  width: 12px;
}

.width--16 {
  max-width: 16px;
}

.width--20 {
  width: 20px;
}

.width--24 {
  width: 24px;
}

.width--32 {
  width: 2rem !important;
}

.width--50px {
  width: 3.125rem;
}

.width--33 {
  width: 33%;
}

.width--48 {
  width: 48%;
}

.width--50 {
  width: 50%;
}

.width--70 {
  width: 70%;
}

.width--80 {
  width: 80%;
}

.width--100 {
  width: 100%;
}

.width--100-imp {
  width: 100% !important;
}

.d-flex {
  display: flex;
}

.content-justify {
  justify-content: space-between;
}

.align-items {
  align-items: end;
}

.align-items-center {
  align-items: center;
}

.overlay {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #001316;
  overflow-x: hidden;
  transition: 0.5s;
}
.overlay .overlay-content {
  position: relative;
  top: 18%;
  width: 100%;
  text-align: center;
  margin-top: -20px;
}
.overlay ul li a {
  padding: 8px;
  text-decoration: none;
  font-size: 1.5rem;
  color: #fafafa;
  display: block;
  transition: 0.3s;
  font-weight: 600;
  margin-bottom: 2rem;
}
.overlay ul li span {
  padding: 8px;
  text-decoration: none;
  font-size: 1.5rem;
  color: #fafafa;
  display: block;
  transition: 0.3s;
  font-weight: 600;
  margin-bottom: 2rem;
}
.overlay:hover {
  color: #f1f1f1;
}
.overlay .closebtn {
  position: absolute;
  top: 20px;
  right: 45px;
  font-size: 60px;
}
.overlay .account--section {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  text-align: center;
  padding: 0px 1rem;
}
.overlay .account--section a {
  margin-bottom: 1rem;
  margin-left: 0 !important;
  margin-right: 0;
  font-weight: 500;
  border-radius: 6px;
  font-size: 0.875rem;
  color: #fafafa;
}
.overlay .account--section a.open-demat-account-btn {
  color: #141b2f;
}
.overlay .account--section a.login-btn {
  color: #75ffff;
}
.overlay .account--section button {
  margin-bottom: 1rem;
  margin-left: 0 !important;
  margin-right: 0;
  color: #fafafa;
  font-weight: 500;
  border-radius: 6px;
  font-size: 0.875rem;
  background-color: #003399;
  border: 1px solid #003399;
  width: 100%;
}

.form--section--get--started {
  margin: 2.75rem 0;
}
.form--section--get--started form {
  margin: 0;
}
.form--section--get--started form p.font-12 {
  bottom: -3.25rem;
}
.form--section--get--started form .warning-msg p {
  margin-bottom: 0;
}

.open--demat--account--section {
  background: #001316;
  position: relative;
  width: 100%;
  float: left;
  padding: 3.25rem 0rem;
}
.open--demat--account--section .container {
  max-width: 1240px;
  margin: auto;
}
.open--demat--account--section h2 {
  margin-bottom: 2.25rem;
  font-weight: 500;
  font-size: 50px;
  line-height: 60px;
  font-family: "neuePowerTrial-Regular";
  color: #fffaf7;
}
.open--demat--account--section h5 {
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 2.25rem;
  margin-bottom: 1.25rem;
  color: #fffaf7;
  font-family: "neuePowerTrial-Medium";
}
.open--demat--account--section p {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.25rem;
  color: #fffaf7;
  font-family: "neueMontreal-Book";
}
.open--demat--account--section p span {
  border-left: 3px solid #75ffff;
  position: relative;
  padding-left: 0.25rem;
}
.open--demat--account--section .open--demat--account--cards--section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 1.25rem;
  margin-bottom: 2rem;
}
.open--demat--account--section .open--demat--account--cards--section .open--demat--account--card {
  border: 1px solid #fffaf7;
  border-radius: 10px;
  padding: 1.625rem 1.375rem 1.625rem 1.375rem;
  width: 23%;
}
.open--demat--account--section .open--demat--account--cards--section .open--demat--account--card .icon--part {
  width: 38px;
  margin-bottom: 0.75rem;
}
.open--demat--account--section .open--demat--account--cards--section .open--demat--account--card p {
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5rem;
  color: #fffaf7;
  margin-bottom: 0.625rem;
  font-family: "neueMontreal-Regular" !important;
}
.open--demat--account--section .open--demat--account--cards--section .open--demat--account--card p:nth-child(2) {
  color: #fffaf7;
  font-family: "neuePowerTrial-Medium" !important;
}
.open--demat--account--section .form--section--get--started {
  justify-content: flex-start;
}

.stock-note {
  background: #f5ebe4;
  border-radius: 20px;
  padding: 1.25rem 1.875rem;
  width: 64%;
  margin-top: 1.375rem;
}
.stock-note p {
  font-family: "neueMontreal-Regular" !important;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.375rem;
  text-align: left;
}
.stock-note p img {
  width: 3.375rem;
  height: 1rem;
  padding-top: 2px;
}
.stock-note p img:nth-child(2) {
  width: 64px;
}

.faq--container--section {
  position: relative;
  width: 100%;
  float: left;
  padding: 5.625rem 0rem;
  background: #001316;
}
.faq--container--section .container {
  max-width: 1240px;
  margin: auto;
  display: flex;
}
.faq--container--section .container .left-part {
  width: 45%;
}
.faq--container--section .container .left-part h2 {
  color: #fffaf7;
  text-align: left;
}
.faq--container--section .container .right-part {
  width: 55%;
}
.faq--container--section .container .right-part .faq-container {
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: auto;
}
.faq--container--section .container .right-part .faq-container details {
  font-size: 1.25rem;
  transition: border-color 0.3s ease-in-out;
}
.faq--container--section .container .right-part .faq-container details summary {
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  cursor: pointer;
  font-weight: 600;
  display: flex;
  align-items: center;
  padding: 1em;
}
.faq--container--section .container .right-part .faq-container details summary .faq-title {
  color: #fffaf7 !important;
  font-size: 1.25rem;
  font-weight: 500;
  flex-grow: 1;
  transition: opacity 250ms ease-in-out;
  position: relative;
  font-family: "PP Neue Montreal", sans-serif;
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.faq--container--section .container .right-part .faq-container details summary .faq-title::before {
  background-color: #fffaf7;
  border-radius: 0.375rem;
  content: "";
  display: inline-block;
  height: 0.45rem;
  left: -20px;
  top: 10px;
  margin-right: 0.5rem;
  position: absolute;
  width: 0.45rem;
}
.faq--container--section .container .right-part .faq-container details summary::-webkit-details-marker {
  display: none;
}
.faq--container--section .container .right-part .faq-container details .faq-content {
  color: #fffaf7;
  padding: 0.2em 1em 1em 1em;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  max-height: 100%;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
}
.faq--container--section .container .right-part .faq-container details .faq-content ul li {
  list-style: disc;
  margin-left: 20px;
}

details[open] .faq-content {
  max-height: 100% !important;
}

.expand-icon {
  margin-left: auto;
  transition: transform 150ms ease-out;
  flex-shrink: 0;
}

details[open] .icon-tabler-circle-plus {
  display: none;
}

details:not([open]) .icon-tabler-circle-minus {
  display: none;
}

.invest--smart--section {
  position: relative;
  width: 100%;
  float: left;
  margin-top: 4.375rem;
}
.invest--smart--section .container {
  max-width: 1240px;
  margin: auto;
  padding: 3.125rem;
  border-radius: 20px;
  text-align: center;
}
.invest--smart--section .container .btn--part {
  margin-top: 4.375rem;
}
.invest--smart--section .container .btn--part a {
  display: block;
  width: -moz-max-content;
  width: max-content;
  margin: auto;
}

.start--journey--section {
  position: relative;
  width: 100%;
  float: left;
  padding: 3.125rem 0px 0px 0px;
  background: #fffaf7;
}
.start--journey--section .container {
  max-width: 1240px;
  margin: auto;
  border-radius: 20px;
  position: relative;
  padding: 0px;
  display: flex;
}
.start--journey--section .container .text--left--part {
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.start--journey--section .container .text--left--part h2 {
  margin-bottom: 2.625rem;
  font-size: 3.125rem;
  font-weight: 600;
  line-height: 3.75rem;
  font-family: "neuePowerTrial-Regular";
  text-transform: capitalize;
}
.start--journey--section .container .text--left--part p {
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.875rem;
  margin-bottom: 2.25rem;
  font-family: "neueMontreal-Regular" !important;
}
.start--journey--section .container .text--left--part a {
  font-size: 1.5rem;
  line-height: 1.75rem;
  font-weight: 500;
  color: #001316;
  background-color: #75ffff;
  padding: 0.75rem 3rem;
  border-radius: 36px;
  display: block;
  width: -moz-fit-content;
  width: fit-content;
  font-family: "neuePowerTrial-Medium";
}
.start--journey--section .container .img-part {
  width: 40%;
}
.start--journey--section .container .img-part .web-view {
  display: block;
}
.start--journey--section .container .img-part .mob-view {
  display: none;
}
.start--journey--section .container .img-part img {
  margin-bottom: -0.5rem;
}

.get--started--section {
  position: fixed;
  z-index: 9;
  bottom: 0;
  display: none;
  width: 100%;
  background: #001316;
  border-top: 2px solid rgba(117, 247, 255, 0.4);
}
.get--started--section .container {
  max-width: 1240px;
  margin: auto;
  width: 80%;
}
.get--started--section .container .get--started--content {
  padding: 0.5rem 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.get--started--section .container .get--started--content .text--part {
  border-radius: 0.625rem;
  background: #00FFCC;
  display: flex;
  gap: 28px;
  align-items: center;
  padding: 2px 68px 2px 2px;
}
.get--started--section .container .get--started--content .text--part .get-started-img {
  border-radius: 0.625rem;
  background-color: #ffffff;
  padding: 0.125rem 1.5rem;
}
.get--started--section .container .get--started--content .text--part .get-started-img img {
  height: 3.4375rem;
}
.get--started--section .container .get--started--content .text--part .get-started-text {
  font-family: "neuePowerTrial-Regular";
  font-size: 1.25rem;
  font-style: italic;
  font-weight: 500;
  line-height: 1.5rem;
  text-align: left;
  color: #001316;
}
.get--started--section .container .get--started--content .form--get--started-sec .custom__search form {
  display: flex;
  gap: 10px;
  position: relative;
}
.get--started--section .container .get--started--content .form--get--started-sec .custom__search form .code {
  background: #293133;
  padding: 0.5rem 0.875rem;
  position: absolute;
  left: 12px;
  border-radius: 6px;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  color: #ffffff;
  top: 8px;
}
.get--started--section .container .get--started--content .form--get--started-sec .custom__search form .custom__search__input {
  border: 1px solid #333333;
  border-radius: 10px;
  height: 54px;
  min-width: 380px;
  background: #111b1d;
  box-sizing: border-box;
  color: #fffaf7;
  font-size: 1rem;
  line-height: 1;
  outline: none;
  padding: 0.938rem 6.25rem 0.938rem 4.5rem;
}
.get--started--section .container .get--started--content .form--get--started-sec .custom__search form input[type=number]::-webkit-inner-spin-button,
.get--started--section .container .get--started--content .form--get--started-sec .custom__search form input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.get--started--section .container .get--started--content .form--get--started-sec .custom__search form input[type=number] {
  -moz-appearance: textfield;
  -webkit-appearance: none;
          appearance: none;
}
.get--started--section .container .get--started--content .form--get--started-sec .custom__search form .custom__search__botton {
  padding: 1.125rem 3rem;
  border-radius: 10px;
  background: #75ffff;
  font-family: "neuePowerTrial-Regular";
  font-size: 16px;
  font-weight: 500;
  line-height: 19.2px;
  border: 0px;
}
.get--started--section .container .get--started--content .form--get--started-sec .custom__search form .referal-code-part {
  display: none;
}

.show,
.get--started--section:hover {
  display: block;
}

.footer--section {
  background: #001316;
  position: relative;
  float: left;
  width: 100%;
  overflow: hidden;
  padding-bottom: 6.875rem !important;
}
.footer--section .container {
  max-width: 1240px;
  margin: auto;
  display: flex;
  justify-content: space-between;
}
.footer--section .container:nth-child(2) {
  margin-bottom: 2rem;
}
.footer--section .container .footer-content {
  display: flex;
  align-items: center;
  background: rgba(117, 255, 255, 0.0509803922);
  padding: 0.734rem 30px 0.734rem 20px;
  border: 1px solid rgba(117, 255, 255, 0.1019607843);
  border-top: none;
  width: 100%;
  border-radius: 0 0 1rem 1rem;
  justify-content: space-between;
}
.footer--section .container .footer-content .web--view {
  display: block;
}
.footer--section .container .footer-content .mobile--view {
  display: none;
}
.footer--section .container .footer-content .footer-logo {
  padding-right: 2.438rem;
}
.footer--section .container .footer-content .footer-logo .logo-footer img {
  max-width: 12.103rem;
}
.footer--section .container .footer-content .footer-logo .logo-content {
  display: flex;
  margin-top: 1.465rem;
  gap: 0.5rem;
  align-items: center;
}
.footer--section .container .footer-content .footer-logo .logo-content .footer-heart-img {
  animation: heartbeat 1s infinite ease-in-out;
}
@keyframes heartbeat {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
}
.footer--section .container .footer-content .footer-logo .logo-content .footer-heart-img img {
  width: 34px !important;
}
.footer--section .container .footer-content .footer-logo .logo-content .footer-text {
  font-family: "neueMontreal-Regular";
}
.footer--section .container .footer-content .footer-logo .logo-content .footer-text .trusted-heading {
  color: #fffaf7;
  opacity: 80%;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.05rem;
}
.footer--section .container .footer-content .footer-logo .logo-content .footer-text p {
  color: #fffaf7;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.2rem;
}
.footer--section .container .footer-content .scanner-button {
  display: flex;
}
.footer--section .container .footer-content .scanner-button .scanner-part {
  position: relative;
  padding-right: 19px;
  border-right: 1px solid rgba(255, 255, 255, 0.08);
  display: flex;
  align-items: center;
}
.footer--section .container .footer-content .scanner-button .scanner-part img {
  width: 8.616rem !important;
}
.footer--section .container .footer-content .download-app-button {
  margin-left: 1.188rem;
  margin-bottom: -15px;
}
.footer--section .container .footer-content .download-app-button .download-logo {
  display: flex;
  border: 0.8px solid #A6A6A6;
  border-radius: 100px;
  padding: 0.845rem 2.608rem;
  margin-bottom: 1rem;
  transition: all 0.3s ease-in-out;
}
.footer--section .container .footer-content .download-app-button .download-logo:hover {
  background-color: #2B3B3D;
  border: 1px solid #75FFFF;
  color: #75FFFF;
}
.footer--section .container .footer-content .download-app-button .download-logo:hover .download-content {
  color: #75ffff;
}
.footer--section .container .footer-content .download-app-button .download-logo:hover .logos img {
  filter: invert(80%) sepia(30%) saturate(600%) hue-rotate(140deg) brightness(500%) contrast(100%);
}
.footer--section .container .footer-content .download-app-button .download-logo .logos img {
  width: 1.488rem !important;
}
.footer--section .container .footer-content .download-app-button .download-logo .download-content {
  margin-left: 0.77rem;
  color: #fffaf7;
}
.footer--section .container .footer-content .download-app-button .download-logo .download-content p {
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.628rem;
  position: relative;
  top: 3px;
}
.footer--section .container .footer-content .accordian {
  height: 30px;
  overflow: hidden;
}
.footer--section .container .footer-content .accordian .footer-content-block .collapsible-toggle {
  cursor: pointer;
  display: inline-block;
  padding: 5px;
}
.footer--section .container .footer-content .accordian .footer-block .links-part ul {
  list-style: none;
  padding: 0;
}
.footer--section .container .footer-content .accordian .footer-block .links-part ul li {
  margin: 10px 0;
}
.footer--section .container .footer-content .accordian .footer-block .links-part ul li a {
  color: #333;
  text-decoration: none;
}
.footer--section .container .footer-content .accordian .footer-block .links-part ul li a:hover {
  text-decoration: underline;
}
.footer--section .container .footer-content .accordian .footer-block.expanded .links-part {
  display: block;
  max-height: 50px;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
}
.footer--section .container .footer-block {
  width: 30%;
  display: flex;
  justify-content: space-between;
}
.footer--section .container .footer-block .links-part {
  width: 45%;
}
.footer--section .container .footer-block .links-part.position {
  width: 50%;
}
.footer--section .container .footer-block .links-part p {
  font-family: "neueMontreal-Regular";
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.2rem;
  text-align: left;
  color: #fffaf7;
  margin-top: 2rem;
  margin-bottom: 0.5rem;
}
.footer--section .container .footer-block .links-part p .footer-title-link {
  color: #fffaf7;
  border-bottom: 1px solid #fffaf7;
}
.footer--section .container .footer-block .links-part ul {
  margin-top: 0rem !important;
  padding-left: 0rem !important;
}
.footer--section .container .footer-block .links-part ul li {
  margin-bottom: 0.375rem;
  list-style: none !important;
}
.footer--section .container .footer-block .links-part ul li a {
  color: #fffaf7;
  font-family: "neueMontreal-Regular" !important;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.188rem;
  text-align: left;
  opacity: 70%;
}
.footer--section .container .footer-block .links-part ul li span {
  color: #fffaf7;
  font-family: "neueMontreal-Regular" !important;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.188rem;
  text-align: left;
  opacity: 70%;
}
.footer--section .container .footer-block .links-part ul p {
  margin-bottom: 0.375rem;
  margin-top: 0;
  list-style: none !important;
}
.footer--section .container .footer-block .links-part ul p a {
  color: #fffaf7;
  font-family: "neueMontreal-Regular" !important;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.188rem;
  text-align: left;
}
.footer--section .container .footer-block .links-part ul p span {
  color: #fffaf7;
  font-family: "neueMontreal-Regular" !important;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.188rem;
  text-align: left;
}
.footer--section .container .footer-block .content-part .first_heading {
  color: #fffaf7;
  font-family: "neueMontreal-Regular";
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.05rem;
  text-align: left;
  margin-bottom: 0.5rem;
  opacity: unset;
}
.footer--section .container .footer-block .content-part .first_heading .first::after {
  border-top: 1px solid #001316;
  content: "";
  position: absolute;
  top: 2rem;
  left: 0;
  right: 0;
  bottom: 0;
  width: 150px;
  z-index: 4;
}
.footer--section .container .footer-block .content-part .location {
  display: flex;
  gap: 0.5rem;
}
.footer--section .container .footer-block .content-part .location img {
  width: 1.25rem;
  height: 1.25rem;
  align-items: center;
}
.footer--section .container .footer-block .content-part .location .second_heading {
  color: #fffaf7;
  font-family: neueMontreal-Regular;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.05rem;
  margin-bottom: 0.5rem;
  text-align: left;
  opacity: unset;
}
.footer--section .container .footer-block .content-part p {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.188rem;
  text-align: left;
  margin-bottom: 1.5rem;
  color: #fffaf7;
  opacity: 70%;
}
.footer--section .container .footer-block .content-part .address-location {
  display: flex;
  gap: 0.5rem;
}
.footer--section .container .footer-block .content-part .address-location img {
  width: 0.852rem;
  height: 1.093rem;
  align-items: center;
}
.footer--section .container .footer-block .content-part .address-location .second_heading {
  color: #fffaf7;
  font-family: "neueMontreal-Regular";
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.05rem;
  text-align: left;
  margin-bottom: 0.5rem;
  opacity: unset;
}
.footer--section .container .footer-block .content-part .social-part {
  margin-bottom: 2.75rem;
  display: flex;
}
.footer--section .container .footer-block .content-part .headings-add {
  font-family: "neuePowerTrial-Regular";
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.5rem;
  text-align: left;
}
.footer--section .container .mobile-view {
  display: none;
}
.footer--section .container .footer-content-block {
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;
}
.footer--section .container .footer-content-block::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background: rgba(177, 177, 177, 0.12);
}
.footer--section .container .footer-content-block p {
  position: relative;
  color: #fffaf7;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.5rem;
  cursor: pointer;
}
.footer--section .container .footer-content-block p::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background: #75ffff;
  z-index: 9;
}
.footer--section .accordion {
  max-height: 50px;
  overflow: hidden;
  transition: all 0.5s ease;
}
.footer--section .accordion.show {
  max-height: 800vw;
  overflow: visible;
}
.footer--section .accordion .footer-content-block svg {
  cursor: pointer;
}
.footer--section .footer-rights-block .container {
  border-top: 1px solid rgb(160, 160, 160);
  padding: 3rem 0px 0px 0px;
}
.footer--section .footer-rights-block .container p {
  color: #fffaf7;
  font-size: 1rem;
  line-height: 1.2rem;
  font-weight: 400;
  font-family: "neueMontreal-Regular";
}
.footer--section .footer-rights-block .container p a {
  color: #fffaf7;
}
.footer--section .footer-rights-block .container .socials {
  color: #fffaf7;
  position: relative;
  font-family: "neueMontreal-Regular";
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.2rem;
  text-align: left;
  left: 15rem;
  top: 3px;
}
.footer--section .footer-rights-block .mobile-view {
  display: none;
}

.who-we-what-we-section {
  position: relative;
  width: 100%;
  float: left;
  margin-top: 0.5rem;
  background: #fffaf7;
}
.who-we-what-we-section .container {
  max-width: 1240px;
  margin: auto;
}
.who-we-what-we-section .container .first-text--part {
  display: flex;
  justify-content: space-between;
}
.who-we-what-we-section .container .first-text--part .heading-part {
  width: 45%;
}
.who-we-what-we-section .container .first-text--part .heading-part h2 {
  font-family: "neuePowerTrial-Regular";
  font-size: 5.625rem;
  font-weight: 500;
  line-height: 6.75rem;
  text-align: left;
  margin-top: 1.875rem;
  margin-bottom: 1.625rem;
}
.who-we-what-we-section .container .first-text--part .heading-part h2 span {
  font-family: "canela-LightItalic";
  font-style: italic;
  font-weight: 100;
}
.who-we-what-we-section .container .first-text--part .description-part {
  width: 45%;
  display: flex;
  align-items: center;
}
.who-we-what-we-section .container .first-text--part .description-part p {
  font-family: "neueMontreal-Regular";
  font-size: 1.25rem;
  font-weight: 400;
  margin-top: 2rem;
  line-height: 2.125rem;
  text-align: left;
}
.who-we-what-we-section .second-text--part {
  border-top: 1px solid #001316;
  border-bottom: 1px solid #001316;
}
.who-we-what-we-section .second-text--part .container {
  display: flex;
  justify-content: space-between;
}
.who-we-what-we-section .second-text--part .container .img-part {
  width: 45%;
  padding-top: 2.25rem;
  padding-bottom: 2.25rem;
  border-right: 1px solid #001316;
}
.who-we-what-we-section .second-text--part .container .img-part img {
  max-width: 26rem;
}
.who-we-what-we-section .second-text--part .container .description-part {
  width: 45%;
  display: flex;
  align-items: center;
}
.who-we-what-we-section .second-text--part .container .description-part p {
  font-family: "neueMontreal-Regular";
  font-size: 1.25rem;
  font-weight: 400;
  margin-top: 1rem;
  line-height: 2.125rem;
  text-align: left;
}
.who-we-what-we-section .third-text--part {
  border-bottom: 1px solid #001316;
}
.who-we-what-we-section .third-text--part .container {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
}
.who-we-what-we-section .third-text--part .container .heading-part {
  width: 40%;
  padding-top: 0.75rem;
  padding-bottom: 1rem;
}
.who-we-what-we-section .third-text--part .container .heading-part h2 {
  font-family: "neuePowerTrial-Regular";
  font-size: 6.625rem;
  font-weight: 500;
  line-height: 8rem;
  text-align: left;
  margin-bottom: 0rem;
}
.who-we-what-we-section .third-text--part .container .heading-part h2 span {
  font-family: "canela-LightItalic";
  font-style: italic;
  font-weight: 100;
}
.who-we-what-we-section .third-text--part .container .heading-part h2 .mob-view {
  display: none;
}
.who-we-what-we-section .third-text--part .container .qr-code-section {
  position: relative;
  float: left;
  width: 100%;
  background: #fffaf7;
}
.who-we-what-we-section .third-text--part .container .qr-code-section .container {
  max-width: 1240px;
  margin: auto;
  display: flex;
  padding: 4rem 0 0 0;
  flex-direction: row-reverse;
}
.who-we-what-we-section .third-text--part .container .qr-code-section .container .left-part {
  width: 35%;
  text-align: center;
  display: flex;
  align-items: end;
}
.who-we-what-we-section .third-text--part .container .qr-code-section .container .left-part .img-part img {
  width: 100%;
  margin-bottom: -1rem;
}
.who-we-what-we-section .third-text--part .container .qr-code-section .container .right-part {
  width: 60%;
}
.who-we-what-we-section .third-text--part .container .qr-code-section .container .right-part h3 {
  color: #001316;
  font-family: "neueMontreal-Regular";
  font-size: 3.75rem;
  font-weight: 500;
  line-height: 4.5rem;
  text-align: left;
  margin-bottom: 1.5rem;
}
.who-we-what-we-section .third-text--part .container .qr-code-section .container .right-part h3 .mob-view {
  display: none;
}
.who-we-what-we-section .third-text--part .container .qr-code-section .container .right-part p {
  color: #001316;
  font-family: "neueMontreal-Regular";
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.8rem;
  text-align: left;
  margin-bottom: 3.75rem;
}
.who-we-what-we-section .third-text--part .container .qr-code-section .container .right-part p .mob-view {
  display: block;
}
.who-we-what-we-section .third-text--part .container .qr-code-section .container .right-part .qr-app-img {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 4.375rem;
  gap: 4rem;
}
.who-we-what-we-section .third-text--part .container .qr-code-section .container .right-part .qr-app-img .qr-img {
  max-width: 10rem;
}
.who-we-what-we-section .third-text--part .container .qr-code-section .container .right-part .qr-app-img .app-img {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.who-we-what-we-section .third-text--part .container .qr-code-section .container .right-part .qr-app-img .app-img a {
  margin-right: 1rem;
}
.who-we-what-we-section .third-text--part .container .qr-code-section .container .right-part .qr-app-img .app-img a img {
  max-width: 12.5rem;
}
.who-we-what-we-section .third-text--part .container .qr-code-section .container .right-part .qr-app-img .app-img .web-view {
  display: block;
}
.who-we-what-we-section .third-text--part .container .qr-code-section .container .right-part .qr-app-img .app-img .mob-view {
  display: none;
}
.who-we-what-we-section .third-text--part .container .description-part {
  width: 55%;
  display: flex;
  align-items: center;
  border-right: 1px solid #001316;
  padding-right: 2rem;
}
.who-we-what-we-section .third-text--part .container .description-part p {
  font-family: "neueMontreal-Regular";
  font-size: 1.25rem;
  font-weight: 400;
  margin-top: 0rem;
  line-height: 2.125rem;
  text-align: left;
}
.who-we-what-we-section .fourth-text--part {
  border-bottom: 1px solid #001316;
}
.who-we-what-we-section .fourth-text--part .container {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}
.who-we-what-we-section .fourth-text--part .container .heading-part {
  width: 40%;
  padding-top: 0.75rem;
  padding-bottom: 1rem;
  border-right: 1px solid #001316;
}
.who-we-what-we-section .fourth-text--part .container .heading-part h2 {
  font-family: "neuePowerTrial-Regular";
  font-size: 6.625rem;
  font-weight: 500;
  line-height: 8rem;
  text-align: left;
  margin-bottom: 0rem;
}
.who-we-what-we-section .fourth-text--part .container .heading-part h2 span {
  font-family: "canela-LightItalic";
  font-style: italic;
  font-weight: 100;
}
.who-we-what-we-section .fourth-text--part .container .description-part {
  width: 55%;
  display: flex;
  align-items: center;
  padding-right: 2rem;
}
.who-we-what-we-section .fourth-text--part .container .description-part p {
  font-family: "neueMontreal-Regular";
  font-size: 1.25rem;
  font-weight: 400;
  margin-top: 0rem;
  line-height: 2.125rem;
  text-align: left;
}
.who-we-what-we-section .fifth-text--part {
  background-color: #001316;
}
.who-we-what-we-section .fifth-text--part .container {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
}
.who-we-what-we-section .fifth-text--part .container .heading-part {
  width: 40%;
  padding-top: 0.75rem;
  padding-bottom: 1rem;
}
.who-we-what-we-section .fifth-text--part .container .heading-part h2 {
  font-family: "neuePowerTrial-Regular";
  font-size: 6.625rem;
  font-weight: 500;
  line-height: 8rem;
  text-align: left;
  margin-bottom: 1.625rem;
  color: #fffaf7;
}
.who-we-what-we-section .fifth-text--part .container .heading-part h2 span {
  font-family: "canela-LightItalic";
  font-style: italic;
  font-weight: 100;
}
.who-we-what-we-section .fifth-text--part .container .description-part {
  width: 55%;
  display: flex;
  align-items: center;
  border-right: 1px solid #fffaf7;
  padding: 3.75rem 2.5rem 3.75rem 0rem;
}
.who-we-what-we-section .fifth-text--part .container .description-part p {
  font-family: "neueMontreal-Regular";
  margin-top: 0rem;
  color: #fffaf7;
  font-size: 33px;
  font-weight: 400;
  line-height: 56.17px;
  text-align: left;
}
.who-we-what-we-section .fifth-text--part .container .img-part {
  width: 45%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.who-we-what-we-section .fifth-text--part .container .img-part img {
  max-width: 330px;
}

.loader {
  width: 48px;
  height: 48px;
  top: 35%;
  left: 48%;
  border-radius: 50%;
  display: inline-block;
  border-top: 3px solid #75ffff;
  border-right: 3px solid transparent;
  box-sizing: border-box;
  animation: rotation 2s linear infinite;
  position: relative;
}

.loader::after {
  position: absolute;
  box-sizing: border-box;
  left: 35px;
  top: 0px;
  width: 10px;
  border-radius: 50%;
  height: 10px;
  background: #ffffff;
  transform: rotate(-40deg);
  box-shadow: rgba(255, 255, 255, 0.5) 0px 54px 55px 1px, rgba(255, 255, 255, 0.5) -1px -2px 20px 17px, rgba(255, 255, 255, 0.12) 0px 4px 6px, rgba(255, 255, 255, 0) 0px 12px 13px, rgba(255, 255, 255, 0.09) 0px -3px 5px;
}

.loader-text {
  position: relative;
  left: 0%;
  right: 0%;
  margin: auto;
  top: 40%;
  width: 350px;
  color: #fffaf7;
  text-align: center;
}
.loader-text p {
  font-family: "neueMontreal-Regular";
  font-size: 1rem;
  font-weight: 500;
  line-height: 16.8px;
  text-align: center;
  margin-bottom: 2rem;
}
.loader-text p:nth-child(2) {
  font-family: "neuePowerTrial-Medium";
  font-size: 3rem;
  font-weight: 500;
  line-height: 43.2px;
  text-align: center;
  margin-bottom: 1.375rem;
}
.loader-text p:nth-child(3) {
  font-size: 1rem;
  font-weight: 400;
  line-height: 19.2px;
  text-align: center;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.key--benefits--section {
  position: relative;
  width: 100%;
  float: left;
  padding: 5rem 7rem;
  background: #fffaf7;
}
.key--benefits--section .container {
  max-width: 1240px;
  margin: auto;
}
.key--benefits--section .container h2 {
  font-family: "neuePowerTrial-Medium";
  font-size: 50px;
  font-weight: 500;
  line-height: 60px;
  text-align: center;
}
.key--benefits--section .container .mobile-view {
  display: none;
}
.key--benefits--section .container .web-view {
  display: block;
}
.key--benefits--section .container .card-sections {
  margin-top: 4.75rem;
  display: flex;
  justify-content: space-between;
}
.key--benefits--section .container .card-sections .common-cards {
  width: 30%;
}
.key--benefits--section .container .card-sections .common-cards .img-part {
  width: 7rem;
  margin: 2rem auto;
}
.key--benefits--section .container .card-sections .common-cards .img-part img {
  width: 7rem;
}
.key--benefits--section .container .card-sections .common-cards h3 {
  font-family: "neuePowerTrial-Medium";
  font-size: 32px;
  font-weight: 500;
  line-height: 38.4px;
  text-align: center;
  margin-bottom: 1.5rem;
}
.key--benefits--section .container .card-sections .common-cards p {
  font-family: "neueMontreal-Regular";
  font-size: 20px;
  font-weight: 400;
  line-height: 29px;
  text-align: center;
}

.single--app--section {
  position: relative;
  width: 100%;
  float: left;
  padding: 5rem 0rem;
  background: #001316;
}
.single--app--section .container {
  max-width: 1240px;
  margin: auto;
  width: 100%;
}
.single--app--section .container h2 {
  text-align: center;
  margin-bottom: 0.875rem;
  text-transform: capitalize;
  font-family: "neuePowerTrial-Regular";
  color: #fffaf7;
}
.single--app--section .container h2 span {
  color: #75ffff;
}
.single--app--section .container h2 br {
  display: none;
}
.single--app--section .container p {
  font-family: "neueMontreal-Regular";
  font-weight: 100;
  line-height: 28.8px;
  text-align: center;
  margin-bottom: 1.5rem;
  font-size: 1.5rem;
  color: #fffaf7;
}
.single--app--section .container a.start--investing {
  background-color: #003399;
  padding: 0.625rem 1.5rem;
  border-radius: 64px;
  color: #fafafa;
  font-size: 1.375rem;
  display: block;
  font-weight: 500;
  line-height: 2rem;
  width: -moz-max-content;
  width: max-content;
  margin: auto;
}
.single--app--section .container a.start--investing img {
  width: 20px;
}
.single--app--section .container .plan--sections {
  padding-top: 4.875rem;
}
.single--app--section .container .plan--sections .bg-blur-img {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
}
.single--app--section .container .plan--sections .plan--container .plan--top--section {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-between;
}
.single--app--section .container .plan--sections .plan--container .plan--top--section a:nth-child(1) {
  width: 49%;
}
.single--app--section .container .plan--sections .plan--container .plan--top--section a:nth-child(1) .card--layout .img--part {
  display: flex;
  margin-bottom: -1.25rem;
}
.single--app--section .container .plan--sections .plan--container .plan--top--section a:nth-child(2) {
  width: 49%;
}
.single--app--section .container .plan--sections .plan--container .plan--top--section a:nth-child(3) {
  width: 23%;
}
.single--app--section .container .plan--sections .plan--container .plan--top--section a:nth-child(5) {
  width: 23%;
}
.single--app--section .container .plan--sections .plan--container .plan--top--section a:nth-child(4) {
  width: 49%;
}
.single--app--section .container .plan--sections .plan--container .plan--top--section a .card--layout {
  border-radius: 20px;
  box-shadow: 0px 4px 67.1px 0px rgba(0, 0, 0, 0.0901960784);
  padding: 1.375rem 0rem 1.375rem 1.375rem;
  position: relative;
  display: flex;
  justify-content: space-between;
  height: 100%;
  border: 1px solid rgba(117, 247, 255, 0.4);
}
.single--app--section .container .plan--sections .plan--container .plan--top--section a .card--layout .arrow-part {
  position: absolute;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  background: #75ffff;
  padding: 0.5rem;
  display: flex;
}
.single--app--section .container .plan--sections .plan--container .plan--top--section a .card--layout .text--part {
  padding-bottom: 1.75rem;
  padding-top: 2.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  margin-right: 0rem;
  min-width: 40%;
}
.single--app--section .container .plan--sections .plan--container .plan--top--section a .card--layout .text--part h3 {
  color: #fffaf7;
  font-size: 1.875rem;
  font-weight: 500;
  line-height: 2.813rem;
  font-family: "neuePowerTrial-Medium";
}
.single--app--section .container .plan--sections .plan--container .plan--top--section a .card--layout .text--part p {
  color: #fffaf7;
  text-align: left;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;
  margin-bottom: 0;
}
.single--app--section .container .plan--sections .plan--container .plan--top--section a .card--layout .img--part {
  margin-bottom: -1.8rem;
  margin-left: 0rem;
}
.single--app--section .container .plan--sections .plan--mobile--section {
  display: none;
}

.faq-section-container {
  position: relative;
  width: 100%;
  float: left;
}
.faq-section-container .container {
  max-width: 1240px;
  margin: auto;
}
.faq-section-container .container h2 {
  font-family: "neueMontreal-Regular";
  color: #141b2f;
  margin: 2.5rem 0 1.5rem 0;
  font-weight: 500;
  font-size: 1.938rem;
  line-height: 2.325rem;
}
.faq-section-container .container .faq-list {
  width: 100%;
}
.faq-section-container .container .faq-list .faq-container {
  width: 100%;
  margin-bottom: 2.5rem;
}
.faq-section-container .container .faq-list .faq-container details {
  font-size: 1.25rem;
  transition: border-color 0.3s ease-in-out;
}
.faq-section-container .container .faq-list .faq-container details summary {
  padding: 0.4rem 0.4rem 0.4rem 0rem;
  display: flex;
}
.faq-section-container .container .faq-list .faq-container details summary .faq-title {
  font-family: "neueMontreal-Regular";
  font-weight: 500;
  text-align: left;
  width: 100%;
  color: #001316;
  display: block;
  font-size: 1.25rem;
  line-height: 1.563rem;
  cursor: pointer;
}
.faq-section-container .container .faq-list .faq-container details summary .faq-title:before {
  position: absolute;
  display: inline-block;
  content: "";
  border-radius: 0.375rem;
  height: 0.45rem;
  width: 0.45rem;
  left: -10px;
  top: 0px !important;
  margin-right: 0.5rem;
  background-color: #fffaf7;
}
.faq-section-container .container .faq-list .faq-container details summary svg {
  cursor: pointer;
}
.faq-section-container .container .faq-list .faq-container details .faq-content {
  color: #001316;
  padding: 1rem 1rem 1rem 0rem;
  font-family: "neueMontreal-Thin";
  font-weight: 600;
  line-height: 25px;
  font-size: 1rem;
  letter-spacing: 1px;
  text-align: left;
}
.faq-section-container .container .faq-list .faq-container details .faq-content ul li {
  list-style-type: none;
  margin-bottom: 1vh !important;
}
.faq-section-container .container .faq-list .faq-container details .faq-content .table-container {
  width: 70%;
  text-align: left;
}
.faq-section-container .container .faq-list .faq-container details .faq-content .table-container .comparison-table {
  border-collapse: collapse;
  border: 1px solid #000;
}
.faq-section-container .container .faq-list .faq-container details .faq-content .table-container .comparison-table thead th {
  padding: 10px;
  border: 1px solid #000;
}
.faq-section-container .container .faq-list .faq-container details .faq-content .table-container .comparison-table tbody tr td {
  padding: 10px;
  border: 1px solid #000;
}
.faq-section-container .container .faq-list .faq-container details .faq-content .table-container .comparison-table tbody tr td span {
  font-weight: 600;
}
.faq-section-container .container .faq-list .faq-container details .faq-content strong {
  color: #001316;
  font-family: "PPNeueMontreal-Medium";
  font-size: 16px;
  font-weight: 500;
}
.faq-section-container .container .faq-list .faq-container details .faq-content h4 {
  margin-top: 2vh !important;
  margin-bottom: 0.5vh !important;
}
.faq-section-container .container .faq-list .faq-container details .sub-list {
  padding-top: 1vh !important;
  padding-left: 5vh !important;
}

@media (max-width: 1199px) {
  .get--started a {
    padding: 0.875rem 1.1rem !important;
  }
}
.by-click {
  display: none;
}
.by-click p a {
  color: #fff;
  text-decoration: underline;
}

.logo img {
  width: 160px !important;
}

@media (max-width: 900px) {
  .form--section--get--started {
    margin: 2.75rem 0;
  }
  .form--section--get--started form {
    margin: 0;
  }
  .form--section--get--started form .error-box {
    bottom: -1rem !important;
    font-size: 0.655rem !important;
    left: 19px;
    max-width: 11.125rem !important;
  }
  .form--section--get--started form p.font-12 {
    bottom: -2.25rem;
  }
  .form--section--get--started form .referal-code-part .referal-code-design-part {
    display: flex;
    align-items: flex-start;
    flex-direction: column !important;
    width: 100%;
    position: absolute;
    height: 4.625rem;
    bottom: -6.8rem;
    left: 0rem;
  }
  .form--section--get--started form .referal-code-part .referal-code-design-part .checkbox-wrapper-4 .cbx {
    font-size: 0.625rem;
    margin: auto;
    padding: 0px 1rem;
  }
  .form--section--get--started form .referal-code-part .referal-code-design-part .code-part {
    padding-left: 0.5rem;
  }
  .form--section--get--started form .referal-code-part .referal-code-design-part .code-part input {
    width: 10.375rem;
  }
  .form--section--get--started form .warning-msg {
    bottom: -8.125rem;
    left: 2.5rem;
  }
  .get--started--section .container {
    width: 100%;
  }
  .get--started--section .container .get--started--content {
    padding: 0.5rem 8px 0.8rem 8px;
    display: block;
  }
  .get--started--section .container .get--started--content .text--part {
    display: none;
  }
  .get--started--section .container .get--started--content .form--get--started-sec .custom__search form {
    position: relative;
  }
  .get--started--section .container .get--started--content .form--get--started-sec .custom__search form .code {
    background: #1C4A54;
    padding: 0.5rem;
    position: absolute;
    left: 12px;
    border-radius: 14px;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    color: #ffffff;
    top: 8px;
  }
  .get--started--section .container .get--started--content .form--get--started-sec .custom__search form .custom__search__input {
    border: 1px solid #75ffff;
    border-radius: 100px;
    height: 48px;
    min-width: 100%;
    width: 100%;
    background: #111b1d;
    box-sizing: border-box;
    color: #fffaf7;
    font-size: 0.75rem;
    line-height: 1;
    outline: none;
    padding: 0.938rem 6.25rem 0.938rem 3.5rem;
  }
  .get--started--section .container .get--started--content .form--get--started-sec .custom__search form input[type=number]::-webkit-inner-spin-button,
  .get--started--section .container .get--started--content .form--get--started-sec .custom__search form input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .get--started--section .container .get--started--content .form--get--started-sec .custom__search form input[type=number] {
    -moz-appearance: textfield;
    -webkit-appearance: none;
            appearance: none;
  }
  .get--started--section .container .get--started--content .form--get--started-sec .custom__search form .custom__search__botton {
    position: absolute;
    right: 10px;
    padding: 0.75rem 1rem;
    border-radius: 100px;
    font-size: 12px;
    font-weight: 700;
    line-height: 14px;
    top: 6px;
  }
  .get--started--section .container .get--started--content .form--get--started-sec .custom__search form .referal-code-part {
    display: none;
  }
  .who-we-what-we-section {
    padding: 1.875rem 0 0rem 0;
  }
  .who-we-what-we-section .container {
    padding: 1rem;
  }
  .who-we-what-we-section .container .first-text--part {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
  .who-we-what-we-section .container .first-text--part .heading-part {
    width: 100%;
  }
  .who-we-what-we-section .container .first-text--part .heading-part h2 {
    font-size: 50px;
    font-weight: 600;
    line-height: 60px;
    text-align: left;
    margin-bottom: 0.875rem;
  }
  .who-we-what-we-section .container .first-text--part .heading-part h2 span {
    font-family: "canela-LightItalic";
    font-style: italic;
    font-weight: 100;
  }
  .who-we-what-we-section .container .first-text--part .description-part {
    width: 100%;
    display: flex;
    align-items: center;
  }
  .who-we-what-we-section .container .first-text--part .description-part p {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    margin-bottom: 2rem;
  }
  .who-we-what-we-section .second-text--part {
    border-top: 1px solid #001316;
    border-bottom: 1px solid #001316;
  }
  .who-we-what-we-section .second-text--part .container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
  .who-we-what-we-section .second-text--part .container .img-part {
    width: 100%;
    padding-top: 2.25rem;
    padding-bottom: 2rem;
    border-right: 0px solid #001316;
  }
  .who-we-what-we-section .second-text--part .container .img-part img {
    max-width: 26rem;
  }
  .who-we-what-we-section .second-text--part .container .description-part {
    width: 100%;
    display: flex;
    align-items: center;
  }
  .who-we-what-we-section .second-text--part .container .description-part p {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    margin-top: 0rem;
  }
  .who-we-what-we-section .third-text--part {
    border-bottom: 1px solid #001316;
  }
  .who-we-what-we-section .third-text--part .container {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    flex-direction: column;
  }
  .who-we-what-we-section .third-text--part .container .heading-part {
    width: 100%;
    padding-top: 0.75rem;
    padding-bottom: 1rem;
  }
  .who-we-what-we-section .third-text--part .container .heading-part h2 {
    font-size: 50px;
    font-weight: 600;
    line-height: 60px;
    text-align: left;
    margin-bottom: 1.625rem;
  }
  .who-we-what-we-section .third-text--part .container .heading-part h2 span {
    font-family: "canela-LightItalic";
    font-style: italic;
    font-weight: 100;
  }
  .who-we-what-we-section .third-text--part .container .heading-part h2 .mob-view {
    display: block;
  }
  .who-we-what-we-section .third-text--part .container .description-part {
    width: 100%;
    display: flex;
    align-items: center;
    border-right: 0px solid #001316;
    padding-right: 0rem;
  }
  .who-we-what-we-section .third-text--part .container .description-part p {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
  }
  .who-we-what-we-section .fourth-text--part {
    border-bottom: 1px solid #001316;
  }
  .who-we-what-we-section .fourth-text--part .container {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-direction: column;
  }
  .who-we-what-we-section .fourth-text--part .container .heading-part {
    width: 100%;
    padding-top: 0.25rem;
    padding-bottom: 1rem;
    border-right: 0px solid #001316;
  }
  .who-we-what-we-section .fourth-text--part .container .heading-part h2 {
    font-size: 50px;
    font-weight: 600;
    line-height: 60px;
    text-align: left;
    margin-bottom: 1.25rem;
  }
  .who-we-what-we-section .fourth-text--part .container .heading-part h2 span {
    font-family: "canela-LightItalic";
    font-style: italic;
    font-weight: 100;
  }
  .who-we-what-we-section .fourth-text--part .container .description-part {
    width: 100%;
    display: flex;
    align-items: center;
    padding-right: 0rem;
  }
  .who-we-what-we-section .fourth-text--part .container .description-part p {
    font-size: 14px;
    font-weight: 400;
    line-height: 31px;
    text-align: left;
  }
  .who-we-what-we-section .fifth-text--part {
    padding-top: 5.5rem;
  }
  .who-we-what-we-section .fifth-text--part .container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
  .who-we-what-we-section .fifth-text--part .container .heading-part {
    width: 40%;
    padding-top: 0.75rem;
    padding-bottom: 1rem;
  }
  .who-we-what-we-section .fifth-text--part .container .heading-part h2 {
    font-family: "neuePowerTrial-Regular";
    font-size: 6.625rem;
    font-weight: 500;
    line-height: 8rem;
    text-align: left;
    margin-bottom: 1.625rem;
    color: #fffaf7;
  }
  .who-we-what-we-section .fifth-text--part .container .heading-part h2 span {
    font-family: "canela-LightItalic";
    font-style: italic;
    font-weight: 100;
  }
  .who-we-what-we-section .fifth-text--part .container .description-part {
    width: 100%;
    display: flex;
    align-items: center;
    border-right: 0px solid #fffaf7;
    padding: 2.5rem 1rem;
  }
  .who-we-what-we-section .fifth-text--part .container .description-part p {
    font-size: 24px;
    font-weight: 400;
    line-height: 40.85px;
    text-align: left;
  }
  .who-we-what-we-section .fifth-text--part .container .img-part {
    width: 100%;
    justify-content: flex-end;
  }
  .who-we-what-we-section .fifth-text--part .container .img-part img {
    width: 50%;
  }
  .faq--container--section {
    padding: 2.5rem 0rem;
    background: #001316;
  }
  .faq--container--section .container {
    padding: 0rem 1rem;
    display: flex;
    flex-direction: column;
  }
  .faq--container--section .container .left-part {
    width: 100%;
  }
  .faq--container--section .container .left-part h2 {
    font-size: 24px;
    font-weight: 500;
    line-height: 28.8px;
    text-align: left;
    font-family: "neuePowerTrial-Regular";
  }
  .faq--container--section .container .right-part {
    width: 100%;
  }
  .faq--container--section .container .right-part .faq-container {
    width: 100%;
  }
  .faq--container--section .container .right-part .faq-container details {
    font-size: 1.25rem;
    transition: border-color 0.3s ease-in-out;
  }
  .faq--container--section .container .right-part .faq-container details summary {
    padding: 1em 1em 1em 1.5em;
  }
  .faq--container--section .container .right-part .faq-container details summary .faq-title {
    font-family: "PP Neue Montreal", sans-serif !important;
    font-size: 14px;
    font-weight: 500;
    text-align: left;
    line-height: 1rem;
  }
  .faq--container--section .container .right-part .faq-container details .faq-content {
    font-size: 12px;
    font-weight: 300;
    line-height: 16px;
    text-align: left;
  }
  .faq--container--section .container .right-part .faq-container details .faq-content ul li {
    margin-left: 5px;
  }
  .open--demat--account--section {
    padding: 3.25rem 1rem;
  }
  .open--demat--account--section .container h2 {
    font-size: 24px;
    font-weight: 500;
    line-height: 28.8px;
    text-align: left;
    margin-bottom: 30px;
    font-family: "neuePowerTrial-Medium";
  }
  .open--demat--account--section .container h2 br {
    display: none;
  }
  .open--demat--account--section .container h5 {
    font-family: "neueMontreal-Book" !important;
    margin-bottom: 28px;
    font-weight: 600;
    font-size: 1rem;
    line-height: 19.2px;
  }
  .open--demat--account--section .container p {
    font-size: 14px;
    font-weight: 400;
    line-height: 14.4px;
    text-align: left;
    font-family: "neueMontreal-Book" !important;
  }
  .open--demat--account--section .container p.small--text-12.mt-05 {
    margin-left: 1rem;
  }
  .open--demat--account--section .open--demat--account--cards--section {
    margin: 2.25rem 0rem;
    flex-direction: column;
    padding: 0 1rem;
  }
  .open--demat--account--section .open--demat--account--cards--section .open--demat--account--card {
    width: 100%;
    margin-bottom: 1.5rem;
  }
  .open--demat--account--section .open--demat--account--cards--section p {
    font-weight: 500;
    font-size: 16px;
    line-height: 19.2px;
  }
  .open--demat--account--section .form--section--get--started {
    margin-top: 0px;
  }
  .start--journey--section {
    padding: 2.625rem 1rem 0rem 1rem;
  }
  .start--journey--section .container {
    flex-direction: column;
  }
  .start--journey--section .container .text--left--part {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
  .start--journey--section .container .text--left--part h2 {
    margin-bottom: 2rem;
    font-size: 2.625rem;
    font-weight: 600;
    line-height: 3.625rem;
    text-align: left;
  }
  .start--journey--section .container .text--left--part p {
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.375rem;
    margin-bottom: 2.25rem;
    text-align: left;
  }
  .start--journey--section .container .text--left--part p br {
    display: none;
  }
  .start--journey--section .container .text--left--part a {
    font-size: 0.875rem;
    line-height: 1rem;
    font-weight: 700;
    padding: 0.75rem 1.125rem;
    border-radius: 20px;
    width: 100%;
    text-align: center;
  }
  .start--journey--section .container .img-part {
    width: 100%;
  }
  .start--journey--section .container .img-part .web-view {
    display: none;
  }
  .start--journey--section .container .img-part .mob-view {
    display: block;
  }
  .start--journey--section .container .img-part img {
    margin-bottom: -0.5rem;
  }
  .invest--smart--section {
    margin-top: 2.5rem;
    padding: 0rem 1rem;
  }
  .invest--smart--section .container {
    padding: 2.875rem 1.5rem 1.625rem;
    border-radius: 20px;
    text-align: center;
  }
  .invest--smart--section .container h2 {
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 2.25rem;
    text-align: center;
  }
  .invest--smart--section .container p {
    font-size: 0.813rem;
    font-weight: 400;
    line-height: 1.25rem;
    text-align: center;
    margin-top: 1.5rem;
  }
  .invest--smart--section .container p br {
    display: none;
  }
  .invest--smart--section .container .btn--part {
    margin-top: 2.5rem;
  }
  .invest--smart--section .container .btn--part a {
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1.125rem;
    text-align: center;
    padding: 1rem 2.5rem;
  }
  .invest--large--btn {
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1.125rem;
    text-align: center;
    padding: 1rem 2.5rem;
  }
  .stock-note {
    padding: 1.5rem 1rem;
    width: 100%;
    margin-top: 1.375rem;
  }
  .stock-note p {
    font-family: "neueMontreal-Regular" !important;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.375rem;
    text-align: left;
  }
  .stock-note p br {
    display: none;
  }
  .sub--btn {
    background-color: #75ffff;
    padding: 0.875rem;
    min-width: 18rem !important;
    display: block;
    color: #fafafa;
    border-radius: 6px;
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 1.375rem;
    text-align: center;
    margin: 4rem auto;
    margin-top: 1rem;
  }
  .footer--section {
    padding-bottom: 0px;
  }
  .footer--section .container {
    padding: 0 1rem 0px 1rem;
    flex-direction: column;
  }
  .footer--section .container:nth-child(2) {
    margin-bottom: 1rem;
  }
  .footer--section .container:nth-child(3) {
    padding: 0rem 1rem 0px 1rem;
  }
  .footer--section .container:nth-child(9) {
    padding: 1.375rem 1rem 1rem 1rem;
  }
  .footer--section .container .footer-content {
    display: flex;
    flex-direction: column;
    justify-content: center !important;
    border-radius: 0 0 1.25rem 1.25rem;
    padding: 1rem;
  }
  .footer--section .container .footer-content .footer-logo {
    padding-right: 0 !important;
  }
  .footer--section .container .footer-content .footer-logo .logo-footer {
    display: flex;
    justify-content: center;
  }
  .footer--section .container .footer-content .footer-logo .logo-footer img {
    max-width: 14.755rem !important;
  }
  .footer--section .container .footer-content .footer-logo .logo-content {
    margin-top: 0 !important;
    padding: 1.9rem;
  }
  .footer--section .container .footer-content .footer-logo .logo-content .footer-heart-img {
    animation: heartbeat 1s infinite ease-in-out;
  }
  @keyframes heartbeat {
    0%, 100% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.2);
    }
  }
  .footer--section .container .footer-content .footer-logo .logo-content .footer-heart-img img {
    width: 46px !important;
  }
  .footer--section .container .footer-content .footer-logo .logo-content .footer-text p {
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.05rem;
    color: #fffaf7;
  }
  .footer--section .container .footer-content .scanner-button {
    display: block;
  }
  .footer--section .container .footer-content .scanner-button .scanner-part {
    border: none;
    margin-bottom: 2.375rem;
    display: flex;
    justify-content: center;
    padding-right: 0 !important;
  }
  .footer--section .container .footer-content .scanner-button .scanner-part img {
    border-bottom: 1px solid rgba(255, 255, 255, 0.08);
    padding-bottom: 19px;
  }
  .footer--section .container .footer-content .download-app-button {
    display: flex;
    gap: 0.524rem;
    margin-left: 0 !important;
    margin-top: -19px;
  }
  .footer--section .container .footer-content .download-app-button .download-logo {
    padding: 14px 27.33px 14px 27.42px;
  }
  .footer--section .container .footer-content .download-app-button .download-logo .logos img {
    width: 0.779rem !important;
  }
  .footer--section .container .footer-content .download-app-button .download-logo .download-content p {
    font-weight: 500;
    font-size: 12px;
    line-height: 15.62px;
  }
  .footer--section .container .footer-block {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .footer--section .container .footer-block .links-part {
    width: 48%;
  }
  .footer--section .container .footer-block .links-part.position {
    width: 51%;
  }
  .footer--section .container .footer-block .links-part p {
    font-family: "neuePowerTrial-Medium";
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.2rem;
    text-align: left;
    color: #fffaf7;
    margin-bottom: 0.5rem;
  }
  .footer--section .container .footer-block .links-part ul {
    margin-top: 0rem !important;
    padding-left: 0rem !important;
  }
  .footer--section .container .footer-block .links-part ul li {
    margin-bottom: 0.375rem;
    list-style: none !important;
  }
  .footer--section .container .footer-block .links-part ul li a {
    color: #fffaf7;
    font-family: "neueMontreal-Regular" !important;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.188rem;
    text-align: left;
  }
  .footer--section .container .footer-block .content-part .first::after {
    border-top: 1px solid #001316;
    content: "";
    position: absolute;
    top: 2rem;
    left: 0;
    right: 0;
    bottom: 0;
    width: 150px;
    z-index: 4;
  }
  .footer--section .container .footer-block .content-part p {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.188rem;
    text-align: left;
    margin-bottom: 1.5rem;
  }
  .footer--section .container .footer-block .content-part .social-part {
    margin-bottom: 2.75rem;
    display: flex;
    justify-content: center;
  }
  .footer--section .container .footer-block .content-part .headings-add {
    font-family: "neuePowerTrial-Regular";
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: left;
  }
  .footer--section .container .footer-block .content-part .add-part {
    display: flex;
    align-items: flex-start;
    margin-bottom: 1.5rem;
  }
  .footer--section .container .footer-block .content-part .add-part:nth-child(2) {
    margin-bottom: 0rem;
  }
  .footer--section .container .footer-block .content-part .add-part p {
    margin-left: 1.5rem;
    font-family: "neueMontreal-Regular" !important;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.25rem;
    text-align: left;
  }
  .footer--section .container .mobile-view {
    display: block;
  }
  .footer--section .container .web-view {
    display: none !important;
  }
  .footer--section .container .container {
    padding: 20px;
    background-color: #f9f9f9;
  }
  .footer--section .container .container .footer-content-block {
    margin-bottom: 15px;
  }
  .footer--section .container .container .footer-content-block p {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  .footer--section .container .container .footer-block {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  .footer--section .container .container .footer-block .links-part {
    flex: 1;
    min-width: 200px;
  }
  .footer--section .container .container .footer-block .links-part p {
    font-size: 14px;
    margin-bottom: 10px;
  }
  .footer--section .container .container .footer-block .links-part ul {
    list-style: none;
    padding: 0;
  }
  .footer--section .container .container .footer-block .links-part ul li {
    margin-bottom: 8px;
  }
  .footer--section .container .container .footer-block .links-part ul li a {
    text-decoration: none;
    color: #333;
    transition: color 0.2s;
  }
  .footer--section .container .container .footer-block .links-part ul li a:hover {
    color: #007bff;
  }
  .footer--section .footer-rights-block {
    padding: 0rem 0rem 7rem 0rem;
  }
  .footer--section .footer-rights-block .container {
    border-top: 1px solid rgb(160, 160, 160);
    padding: 3rem 0px 2rem 0px;
  }
  .footer--section .footer-rights-block .container .web-view {
    display: none;
  }
  .footer--section .footer-rights-block .container .socials {
    text-align: center;
    position: relative;
    top: 0;
    left: 0;
    margin-bottom: 1.188rem;
  }
  .footer--section .footer-rights-block .container .social-part {
    margin: auto;
  }
  .footer--section .footer-rights-block .container p {
    margin-top: 1.5rem;
    text-align: center;
  }
  .footer--section .footer-rights-block .container a {
    color: #001316;
  }
  .footer--section .footer-rights-block .mobile-view {
    display: block;
    color: white;
    text-align: center;
  }
  .footer--section .footer-rights-block .mobile-view a {
    color: white;
  }
  .error-box {
    bottom: -1rem !important;
    font-size: 0.655rem !important;
    left: 19px;
    max-width: 11.125rem !important;
  }
  .loader {
    left: 45%;
  }
  .single--app--section {
    padding: 3.75rem 1rem;
  }
  .single--app--section .mb-bg-blur-img {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
  }
  .single--app--section .container h2 {
    margin-bottom: 0.875rem;
    font-family: "neuePowerTrial-Medium";
    font-size: 2.625rem;
    font-weight: 500;
    line-height: 57.6px;
    text-align: left;
  }
  .single--app--section .container h2 span {
    color: #75ffff;
  }
  .single--app--section .container p {
    font-size: 1.5rem;
    line-height: 1.5rem;
    margin-bottom: 1.25rem;
    font-family: "neueMontreal-Thin";
    text-align: left;
  }
  .single--app--section .container a.start--investing {
    padding: 0.375rem 1rem;
    font-size: 0.75rem;
    line-height: 2rem;
    width: -moz-max-content;
    width: max-content;
    display: flex;
    justify-content: center;
  }
  .single--app--section .container a.start--investing img {
    margin-left: 0.5rem;
  }
  .single--app--section .container .plan--sections {
    padding-top: 3.75rem;
  }
  .single--app--section .container .plan--sections .plan--container {
    display: none;
  }
  .single--app--section .container .plan--sections .plan--mobile--section {
    display: block;
    padding: 0rem;
  }
  .single--app--section .container .plan--sections .plan--mobile--section .card--layout {
    border-radius: 20px;
    margin-bottom: 1.5rem;
    box-shadow: 0px 4px 67.1px 0px rgba(0, 0, 0, 0.0901960784);
    padding: 1.375rem 1.375rem 0rem 1.375rem;
    background-color: rgba(0, 19, 22, 0);
    position: relative;
    display: flex;
    justify-content: space-between;
    height: 100%;
    flex-direction: column;
    border: 1px solid rgba(117, 247, 255, 0.4);
  }
  .single--app--section .container .plan--sections .plan--mobile--section .card--layout .arrow-part {
    position: absolute;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    background: #75ffff;
    padding: 0.5rem;
    display: flex;
  }
  .single--app--section .container .plan--sections .plan--mobile--section .card--layout .text--part {
    padding-top: 2.5rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    margin-right: 0rem;
  }
  .single--app--section .container .plan--sections .plan--mobile--section .card--layout .text--part h3 {
    text-align: left;
    color: #fffaf7;
    margin-bottom: 0.5rem;
    font-family: "neuePowerTrial-Medium";
    font-size: 30px;
    font-weight: 500;
    line-height: 36px;
  }
  .single--app--section .container .plan--sections .plan--mobile--section .card--layout .text--part p {
    font-family: "neueMontreal-Regular";
    font-size: 1rem;
    font-weight: 400;
    text-align: left;
    line-height: 1.5rem;
    color: #fffaf7;
    margin-bottom: 1.5rem;
  }
  .single--app--section .container .plan--sections .plan--mobile--section .card--layout .img--part {
    margin-bottom: -5px;
  }
  .key--benefits--section {
    padding: 2rem 1rem 0rem 1rem;
  }
  .key--benefits--section .container {
    max-width: 1240px;
    margin: auto;
  }
  .key--benefits--section .container h2 {
    font-size: 3rem;
    font-weight: 500;
    line-height: 3.625rem;
    text-align: left;
  }
  .key--benefits--section .container .mobile-view {
    display: block;
  }
  .key--benefits--section .container .web-view {
    display: none;
  }
  .key--benefits--section .container .card-sections {
    margin-top: 2rem;
    flex-direction: column;
  }
  .key--benefits--section .container .card-sections .common-cards {
    width: 100%;
    padding-bottom: 3rem;
  }
  .key--benefits--section .container .card-sections .common-cards .img-part {
    margin: 0rem;
  }
  .key--benefits--section .container .card-sections .common-cards .img-part img {
    width: 7rem;
  }
  .key--benefits--section .container .card-sections .common-cards h3 {
    margin-top: 2rem;
    font-size: 28px;
    font-weight: 500;
    line-height: 33.6px;
    text-align: left;
  }
  .key--benefits--section .container .card-sections .common-cards p {
    font-size: 1rem;
    font-weight: 400;
    line-height: 25px;
    text-align: left;
    font-family: "neueMontreal-Regular";
  }
  .by-click {
    display: block;
    text-align: center;
    padding-top: 10px;
  }
  .by-click p {
    font-size: 12px;
    font-weight: 400;
    color: #fff;
  }
  .faq-section-container {
    padding: 0px 16px;
  }
  .faq-section-container .container h2 {
    font-size: 28px;
  }
  .faq-section-container .faq-list .faq-container details .faq-content .table-container {
    width: 100% !important;
  }
  .faq-section-container .faq-list .faq-container details .faq-content ul li {
    font-size: 14px;
  }
  .faq-section-container .faq-list .faq-container details summary .faq-title {
    font-size: 16px !important;
  }
}
.faq-section-container .container .faq-list .faq-container details .faq-content .table-container .over-flow-hidden {
  overflow: hidden;
}

.custom-overlay {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  overflow-y: scroll;
  z-index: 1000000;
}
.custom-overlay #login-overlay-content {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 50px;
  color: #001316;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  width: 27rem;
  height: 18rem;
  background: #fffaf7;
  border-radius: 0.5rem;
  padding: 1.5rem 2.5rem 1.875rem;
  text-align: center;
}
.custom-overlay #login-overlay-content img {
  width: 3.25rem;
  text-align: center;
}
.custom-overlay #login-overlay-content .close-btn {
  position: absolute;
  top: -16px;
  right: 16px;
  cursor: pointer;
}
.custom-overlay #login-overlay-content .close-btn img {
  width: 14px;
}
.custom-overlay #login-overlay-content .alert {
  font-family: "neueMontreal-Regular";
  font-size: 1.375rem;
  font-weight: 500;
  line-height: 26.4px;
  text-align: center;
}
.custom-overlay #login-overlay-content p {
  font-family: "neueMontreal-Regular";
  font-size: 14px;
  font-weight: 600;
  line-height: 16.8px;
  text-align: center;
  margin-top: 1rem;
}
.custom-overlay #login-overlay-content .expl-btn {
  margin-top: 2rem;
}
.custom-overlay #login-overlay-content .expl-btn a {
  width: 100%;
  display: block;
  background: #75ffff;
  color: #001316;
  font-family: "neuePowerTrial-Medium";
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.25rem;
  text-align: center;
  padding: 0.875rem 1rem;
}

.error-box {
  position: absolute;
  bottom: -1.2rem;
  left: 1rem;
  background-color: transparent;
  width: 100%;
  max-width: 14.8rem;
  margin: 0px;
  color: #e50914;
  text-align: center;
  font-size: 0.875rem;
  line-height: 0.8rem;
}

.hv-modal {
  background-color: rgba(7, 8, 23, 0.85);
  height: 100dvh;
  left: 0;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100vw;
  z-index: 99999999 !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hv-modal iframe {
  height: clamp(300px, 100dvh, 600px);
  width: clamp(320px, 100vw, 500px);
}

/*
  New Onboarding Modal
*/
.popup-layer {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 100000;
  display: none;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
}
.popup-layer.show {
  display: flex;
}

.baseModalPopup {
  background-color: #ffffff;
  border-radius: 15px;
  display: none;
  overflow: hidden;
  max-height: 820px;
}
.baseModalPopup .modal-content {
  padding: 2.125rem 1rem 1rem 1rem;
  flex-direction: column;
}
.baseModalPopup.bg-orange {
  background-color: #FFECA8;
}
.baseModalPopup .loader-container {
  display: none;
}
.baseModalPopup .popup-title {
  font-family: "neuePowerTrial-Regular";
  text-align: center;
  font-size: 1.875rem;
  font-weight: 500;
  line-height: 2.25rem;
}
.baseModalPopup input[type=radio] {
  display: none;
}
.baseModalPopup .button-position {
  padding-top: 16px;
  text-align: center;
  background: white;
  padding-bottom: 16px;
}
.baseModalPopup .button-position button {
  padding: 15px 8.75rem;
  border-radius: 175px;
  font-size: 15px;
  font-weight: 500;
  line-height: 19.53px;
  text-align: center;
  background: #001316;
  color: #ffffff;
}
.baseModalPopup .button-position button.full {
  width: 100%;
}
.baseModalPopup .button-position button.black {
  background: #001316;
  color: #ffffff;
  cursor: pointer;
}
.baseModalPopup .button-position button.cyan {
  background: #75FFFF;
  cursor: pointer;
  border: 0;
  color: #000;
}
.baseModalPopup .button-position button:disabled {
  background: #555555;
  cursor: pointer;
  color: #fff;
}

.baseModalPopup .free-sticky {
  background: #ffd747;
  font-size: 16px;
  font-weight: 600;
  line-height: 20.83px;
  text-align: center;
  border-top-right-radius: 15px;
  border-bottom-left-radius: 15px;
  position: absolute;
  padding: 8px 18px;
  right: 0;
  top: 0;
}

.baseModalPopup.show {
  display: block;
}

/* Account list Modal */
.accounts-list-content {
  max-width: 430px;
  max-height: 820px;
  width: 95vw;
  height: 95vh;
  display: flex;
  flex-direction: column;
}
.accounts-list-content .bank-selection {
  max-width: 600px;
  font-family: Arial, sans-serif;
  width: 95%;
  margin: 20px auto;
  margin-bottom: 0px;
}
.accounts-list-content .bank-selection h2 {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}
.accounts-list-content .bank-selection .description {
  font-size: 16px;
  color: #333;
  padding: 0px;
  margin-bottom: 0px;
}
.accounts-list-content .accounts {
  flex: 1;
  overflow-y: auto;
  overflow-x: visible;
  max-height: calc(100% - 150px);
  margin-top: 10px;
}
.accounts-list-content .accounts .accounts-list {
  display: flex;
  flex-direction: column;
  margin: 10px auto;
  border-radius: 8px;
  box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;
  overflow: hidden;
  width: 95%;
  background: #fff;
  align-items: center;
}
.accounts-list-content .accounts .accounts-list .account-item {
  display: flex;
  gap: 10px;
  padding: 20px;
  cursor: pointer;
}
.accounts-list-content .accounts .accounts-list .account-item:hover {
  transition: all 0.5s ease-in-out;
  background-color: #ECECEC;
}
.accounts-list-content .accounts .accounts-list .account-item:not(:last-child) {
  border-bottom: 1px solid #ddd;
}
.accounts-list-content .accounts .accounts-list .account-item input[type=radio] {
  accent-color: black;
  cursor: pointer;
  display: block !important;
  height: 40px;
  width: 20px;
}
.accounts-list-content .accounts .accounts-list .account-item .account-info {
  flex: 1;
}
.accounts-list-content .accounts .accounts-list .account-item .account-info .bank-header {
  font-size: 17px;
  font-weight: bold;
  color: #333;
}
.accounts-list-content .accounts .accounts-list .account-item .account-info .account-type {
  font-size: 15px;
  color: #666;
  font-weight: bold;
  margin-top: 6px;
}
.accounts-list-content .accounts .accounts-list .account-item .account-info .account-details {
  margin-top: 8px;
}
.accounts-list-content .accounts .accounts-list .account-item .account-info .account-details ul {
  list-style-type: disc;
  padding-left: 18px;
  margin: 0;
  color: #444;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.accounts-list-content .accounts .accounts-list .account-item .account-info .account-details ul li {
  font-size: 15px;
  margin-bottom: 3px;
}

/* Consent Screen Modal */
.consent-screen-3-in-1 {
  max-width: 430px;
  max-height: 820px;
  width: 95vw;
  height: 95vh;
  display: flex;
  flex-direction: column;
}
.consent-screen-3-in-1 .consent-body {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.consent-screen-3-in-1 .consent-body .torus-logo {
  width: 200px;
  margin: 60px 0px;
}
.consent-screen-3-in-1 .consent-body span {
  margin-bottom: 20px;
  text-align: center;
}
.consent-screen-3-in-1 .consent-body span:nth-child(2) {
  font-size: 32px;
}
.consent-screen-3-in-1 .consent-body span:nth-child(3) {
  font-size: 50px;
}
.consent-screen-3-in-1 .consent-body span:nth-child(4) {
  font-size: 57px;
  font-style: italic;
}
.consent-screen-3-in-1 .consent-body span:nth-child(5) {
  font-size: 32px;
  margin-bottom: 0px;
  font-style: italic;
  display: flex;
  flex-direction: column;
}
.consent-screen-3-in-1 .consent-body span:nth-child(5) .slideshow {
  height: 50px;
  overflow: hidden;
  position: relative;
}
.consent-screen-3-in-1 .consent-body span:nth-child(5) .slideshow .slideshow-inner {
  display: flex;
  flex-direction: column;
  animation: slide 6s ease-in-out infinite;
}
@keyframes slide {
  0%, 16% {
    transform: translateY(0);
  }
  24%, 40% {
    transform: translateY(-60px);
  }
  48%, 64% {
    transform: translateY(-120px);
  }
  72%, 80% {
    transform: translateY(-180px);
  }
  88%, 100% {
    transform: translateY(-240px);
  }
}
.consent-screen-3-in-1 .consent-body span:nth-child(5) .slideshow .slideshow-inner div {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 32px;
}
.consent-screen-3-in-1 .consent-body span:nth-child(5) img {
  height: 5px;
  color: black;
  width: 100%;
  margin: 0;
}
.consent-screen-3-in-1 .consent-controls {
  background-color: white;
  border-radius: 25px 25px 0px 0px;
  padding: 40px 15px;
}
.consent-screen-3-in-1 .consent-controls .consent-options {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.consent-screen-3-in-1 .consent-controls .consent-options .consent-option {
  display: flex;
  font-size: 12px;
}
.consent-screen-3-in-1 .consent-controls .consent-options .consent-option input[type=checkbox] {
  display: none;
}
.consent-screen-3-in-1 .consent-controls .consent-options .consent-option input[type=checkbox]:checked + label .consent-checkbox {
  background-color: #00FFCC;
  border: 0;
}
.consent-screen-3-in-1 .consent-controls .consent-options .consent-option input[type=checkbox]:checked + label .consent-checkbox .checkmark {
  display: flex;
}
.consent-screen-3-in-1 .consent-controls .consent-options .consent-option .consent-checkbox {
  background-color: white;
  height: 20px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid black;
  cursor: pointer;
  margin-right: 10px;
  margin-top: 5px;
}
.consent-screen-3-in-1 .consent-controls .consent-options .consent-option .consent-checkbox .checkmark {
  width: 12px;
  display: none;
}
.consent-screen-3-in-1 .consent-controls .consent-options .consent-option .consent-text {
  font-size: 12px;
}
.consent-screen-3-in-1 .consent-controls .powered-by-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  font-size: 14px;
  margin-bottom: 10px;
}
.consent-screen-3-in-1 .consent-controls .powered-by-content img {
  width: 150px;
}

#account-confirmation {
  max-width: 430px;
  max-height: 820px;
  width: 95vw;
  height: 95vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
  gap: 20px;
}
#account-confirmation .title {
  font-size: 20px;
  margin: 20px 0px;
}
#account-confirmation .bank-card-illustration {
  width: 65%;
  flex: 1;
}
#account-confirmation .text {
  font-size: 20px;
}
#account-confirmation .consent-options {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
#account-confirmation .consent-options .consent-option {
  display: flex;
  font-size: 12px;
}
#account-confirmation .consent-options .consent-option input[type=checkbox] {
  display: none;
}
#account-confirmation .consent-options .consent-option input[type=checkbox]:checked + label .consent-checkbox {
  background-color: #00FFCC;
  border: 0;
}
#account-confirmation .consent-options .consent-option input[type=checkbox]:checked + label .consent-checkbox .checkmark {
  display: flex;
}
#account-confirmation .consent-options .consent-option .consent-checkbox {
  background-color: white;
  height: 20px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid black;
  cursor: pointer;
  margin-right: 10px;
  margin-top: 5px;
}
#account-confirmation .consent-options .consent-option .consent-checkbox .checkmark {
  width: 12px;
  display: none;
}
#account-confirmation .consent-options .consent-option .consent-text {
  font-size: 12px;
}

.spinner {
  height: 65px;
  width: 65px;
  border: 8px solid rgba(117, 255, 255, 0.3);
  border-top-color: #75ffff;
  border-radius: 50%;
  margin: 30px auto;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/*SUCCESS SCREEN*/
.bank-success {
  padding: 20px;
  max-width: 430px;
  max-height: 820px;
  width: 95vw;
  height: 95vh;
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: end;
  background-color: #DCFFFA;
  color: #001316;
  position: relative;
}
.bank-success .checkmark-gif {
  position: absolute;
  top: 30px;
  left: 50%;
  transform: translateX(-50%);
  width: 80%;
}
.bank-success .bank-success-content {
  display: flex;
  flex-direction: column;
}
.bank-success .bank-success-content .bank-success-heading {
  font-size: 28px;
  font-weight: 600;
  margin-top: 70%;
  margin-bottom: 15px;
}
.bank-success .bank-success-content .bank-success-text {
  font-size: 20px;
  font-weight: 500;
}
.bank-success .bank-success-content .redirect-box {
  padding: 3px;
  margin: 60px 0px;
  border-radius: 15px;
  background: linear-gradient(45deg, #337984, #00FFCC);
}
.bank-success .bank-success-content .redirect-box .redirect-content {
  padding: 15px;
  border-radius: 13px;
  background-color: #FDF6E7;
  font-weight: 500;
}
.bank-success .bank-success-content .redirect-box .redirect-content .progress-bar {
  width: 36px;
  height: 36px;
  background-color: #C6FFF0;
  animation: progressAnimation 2s ease-in-out forwards;
  margin: auto;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  border-radius: 36px;
}
@keyframes progressAnimation {
  0% {
    color: transparent;
    width: 36px;
  }
  33% {
    background-color: #3FFDF6;
  }
  66% {
    color: transparent;
    background-color: #00FFCC;
  }
  100% {
    color: white;
    width: 90%;
    background-color: #FFCD60;
  }
}
.bank-success .bank-success-content .disclaimer {
  color: rgba(0, 19, 22, 0.5019607843);
  font-size: 15px;
  margin-bottom: 20px;
  margin-top: 50px;
}

.grid-layout {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  max-width: 900px;
}

.base-card-comp {
  position: relative;
  border-radius: 16px;
  margin-top: 2.125rem;
  width: 375px;
  padding: 2.5px;
}
.base-card-comp .base-card-top-comp {
  padding: 0.625rem 0.625rem 0 0.625rem;
}
.base-card-comp .base-card-top-comp .base-card-title {
  font-family: "neuePowerTrial-Regular";
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.5rem;
  text-align: center;
  margin-bottom: 20px;
}
.base-card-comp .base-card-top-comp .account-container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 10px;
  margin-bottom: 1.25rem;
}
.base-card-comp .base-card-top-comp .account-container .common-card {
  padding: 10px;
}
.base-card-comp .base-card-top-comp .account-container .common-card:nth-child(2) {
  position: relative;
}
.base-card-comp .base-card-top-comp .account-container .common-card:nth-child(2)::after {
  content: "";
  width: 2px;
  height: 120px;
  left: -10px;
  background: #bce7f0;
  position: absolute;
  top: 0px;
}
.base-card-comp .base-card-top-comp .account-container .common-card:nth-child(2)::before {
  content: "";
  width: 2px;
  height: 120px;
  right: -5px;
  background: #bce7f0;
  position: absolute;
  top: 0px;
}
.base-card-comp .base-card-top-comp .account-container .common-card .common-card-title {
  font-family: "neuePowerTrial-Regular";
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.2rem;
  text-align: left;
  margin-bottom: 1rem;
}
.base-card-comp .base-card-top-comp .account-container .common-card .common-card-bottom .flat-title {
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 0.9762rem;
  text-align: left;
  margin-bottom: 0.25rem;
}
.base-card-comp .base-card-top-comp .account-container .common-card .common-card-bottom .percent-title span:nth-child(1) {
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.5rem;
  text-align: left;
  font-family: "neuePowerTrial-Regular";
}
.base-card-comp .base-card-top-comp .account-container .common-card .common-card-bottom .percent-title span:nth-child(2) {
  font-size: 10px;
  line-height: 12px;
  font-family: "neuePowerTrial-Regular";
}
.base-card-comp .base-card-bottom-comp {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 1rem;
  background: #ebf7fa;
  border-bottom-right-radius: 16px;
  border-bottom-left-radius: 16px;
}
.base-card-comp .base-card-bottom-comp .img-part {
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  border-radius: 50%;
  background: #ffffff;
}
.base-card-comp .base-card-bottom-comp .footer-text span {
  font-size: 16px;
  font-weight: 700;
  line-height: 20.83px;
  text-align: left;
}
.base-card-comp .base-card-bottom-comp .footer-text span:nth-child(2) {
  font-family: "canela-LightItalic";
  font-size: 16px;
  font-style: italic;
  font-weight: 400;
  line-height: 19.2px;
  text-align: left;
}
.base-card-comp .free-sticker {
  background: #ffd474;
}

.base-card-comp:hover, .base-card-comp:has(input[type=radio]:checked) {
  background: conic-gradient(from 176.08deg at 50% 50%, #ffdb59 0deg, #ffffff 84.6deg, #febb48 176.4deg, #ffffff 268.2deg, #ffd028 360deg);
}

.base-card-comp-second {
  position: relative;
  border-radius: 16px;
  margin-top: 2.125rem;
  width: 375px;
  padding: 2.5px;
  overflow: hidden;
}
.base-card-comp-second .base-card-top-comp {
  padding: 0.625rem 0.625rem 0 0.625rem;
}
.base-card-comp-second .base-card-top-comp .base-card-title {
  font-family: "neuePowerTrial-Regular";
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.5rem;
  text-align: center;
  margin-bottom: 20px;
}
.base-card-comp-second .base-card-top-comp .account-container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 10px;
  margin-bottom: 1.25rem;
  position: relative;
}
.base-card-comp-second .base-card-top-comp .account-container::after {
  content: "";
  width: 2px;
  height: 150px;
  left: 0px;
  right: 0;
  margin: auto;
  background: #bce7f0;
  position: absolute;
  top: 14px;
}
.base-card-comp-second .base-card-top-comp .account-container .common-card {
  padding: 40px 30px;
  width: 48%;
}
.base-card-comp-second .base-card-top-comp .account-container .common-card .common-card-title {
  font-family: "neuePowerTrial-Regular";
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.2rem;
  text-align: left;
  margin-bottom: 1rem;
}
.base-card-comp-second .base-card-top-comp .account-container .common-card .common-card-bottom .flat-title {
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 0.9762rem;
  text-align: left;
  margin-bottom: 0.25rem;
}
.base-card-comp-second .base-card-top-comp .account-container .common-card .common-card-bottom .percent-title span:nth-child(1) {
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.5rem;
  text-align: left;
  font-family: "neuePowerTrial-Regular";
}
.base-card-comp-second .base-card-top-comp .account-container .common-card .common-card-bottom .percent-title span:nth-child(2) {
  font-size: 10px;
  line-height: 12px;
  font-family: "neuePowerTrial-Regular";
}
.base-card-comp-second .free-sticker {
  background: #ffd474;
}

.content {
  background-color: #f8fbfb;
  height: auto;
  width: 100%;
  border-radius: 12px;
  cursor: pointer;
}

.base-card-comp-second:hover, .base-card-comp-second:has(input[type=radio]:checked) {
  background: conic-gradient(from 176.08deg at 50% 50%, #ffdb59 0deg, #ffffff 84.6deg, #febb48 176.4deg, #ffffff 268.2deg, #ffd028 360deg);
}

.width--36 {
  width: 2.25rem !important;
}

.rotatingText {
  align-items: center;
  display: flex;
  text-align: center;
  justify-content: center;
}
.rotatingText .rotatingText-content {
  position: relative;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
}
.rotatingText .rotatingText-content .rotatingText-adjective {
  margin-bottom: 0;
  margin-left: 0 !important;
  left: 0;
  position: absolute;
  animation: rotate-text-loop 3s infinite;
  cursor: pointer;
}
.rotatingText .rotatingText-content .rotatingText-adjective:nth-of-type(1) {
  animation-delay: 0s;
}
.rotatingText .rotatingText-content .rotatingText-adjective:nth-of-type(2) {
  animation-delay: 1.5s;
  opacity: 0;
}

@keyframes rotate-text-loop {
  0% {
    transform: translateY(40px);
    opacity: 0;
  }
  10%, 30% {
    transform: translateY(0);
    opacity: 1;
  }
  40%, 100% {
    transform: translateY(-40px);
    opacity: 0;
  }
}
@media (max-width: 900px) {
  .baseModalPopup.full {
    width: 100%;
    padding: 0rem 1rem 0rem;
    max-height: 100vh;
    height: 100vh;
    overflow: scroll;
    border-radius: 0px !important;
  }
  .baseModalPopup .popup-title {
    position: sticky;
    z-index: 111111111;
    padding: 2.125rem 0 1rem;
    top: 0px;
    background: white;
  }
  .baseModalPopup .button-position {
    position: sticky;
    bottom: 0px;
    padding-top: 16px;
    text-align: center;
    background: white;
    padding-bottom: 16px;
  }
  .grid-layout {
    grid-template-columns: 1fr;
  }
  .base-card-top-comp {
    padding: 0.625rem 0.25rem 0 0.25rem !important;
  }
  .base-card-top-comp .account-container {
    gap: 5px !important;
  }
  .base-card-comp, .base-card-comp-second {
    width: 100% !important;
  }
  .rotatingText .rotatingText-content {
    align-items: anchor-center;
  }
  .rotatingText .rotatingText-content .rotatingText-adjective {
    margin-bottom: 0;
    margin-left: 0 !important;
    position: absolute;
    animation: rotate-text-loop 3s infinite;
    cursor: pointer;
    opacity: 0;
    text-align: center;
    left: 50%;
    transform: translateX(-50%) !important;
  }
}
.ssfb-modal {
  background-color: rgba(7, 8, 23, 0.85);
  height: 100dvh;
  left: 0;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100vw;
  z-index: 99999999 !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ssfb-modal iframe {
  height: clamp(300px, 100dvh, 600px);
  width: clamp(340px, 100vw, 600px);
  border: none;
  border-radius: 1rem;
}

.no-scroll {
  overflow: hidden;
}

.start-investment-card {
  background: #75ffff;
  border-radius: 20px;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}
.start-investment-card h2 {
  font-family: "neuePowerTrial-Regular";
  font-size: 1.75rem;
  font-weight: 700;
  line-height: 2.175rem;
  text-align: left;
  color: #001316;
}
.start-investment-card .yellow-strip {
  padding: 0.75rem 1.5rem;
  margin-left: -1.5rem;
  background: #dbfe87;
  border-top-right-radius: 6.25rem;
}
.start-investment-card .yellow-strip h4 {
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.35rem;
  font-family: "neuePowerTrial-Regular";
  color: #001316;
}
.start-investment-card .yellow-strip h4 span {
  font-family: "canela-LightItalic";
  font-size: 1.125rem;
  font-style: italic;
  font-weight: 700;
  line-height: 1.35rem;
  text-align: left;
}
.start-investment-card .zero-charge {
  display: flex;
  align-items: end;
}
.start-investment-card .zero-charge .img-part {
  width: 9.375rem;
}
.start-investment-card .zero-charge .text-part h5 {
  font-family: "neuePowerTrial-Regular";
  font-size: 2.3363rem;
  font-style: italic;
  font-weight: 500;
  line-height: 2.4169rem;
  text-align: left;
}
.start-investment-card .button-section a {
  display: block;
  border-radius: 36px;
  background-color: #001316;
  color: #75ffff;
  padding: 0.75rem;
  font-family: "neuePowerTrial-Regular";
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.05rem;
  text-align: left;
  text-align: center;
}/*# sourceMappingURL=common-styles.css.map */
